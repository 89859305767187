// @flow
import React from 'react';
import type { FrontMatter, ImageType } from '../../utils/types';
import {
  AllImagesLink,
  CanyonRatingCard,
  Carousel,
  GlossaryLink as G,
  Image,
  Layout,
  Link,
  withFrontMatter,
} from '../../components';
import { graphql } from 'gatsby';
import route from '../../routes';

type Props = {
  frontMatter: FrontMatter,
  data: {
    opengraph: ImageType,
    P8070341: ImageType,
    P8070347: ImageType,
    P8070354: ImageType,
    P8070360: ImageType,
    P8070375: ImageType,
    P8070387: ImageType,
    P8070388: ImageType,
    P8070389: ImageType,
    P8070390: ImageType,
    P8070394: ImageType,
    P8070404: ImageType,
    P8070385: ImageType,
    P8070399: ImageType,
    P8070425: ImageType,
  },
};

class Blog_2021_08_07_Hall_Creek extends React.PureComponent<Props> {
  render() {
    const { data, frontMatter } = this.props;

    return (
      <Layout frontMatter={frontMatter}>
        <p>
          If you haven't already, check out the previous days' trip report where
          we descended{' '}
          <Link to={route.blog_2021_08_06_south_fork_snoqualmie}>
            South Fork Snoqualmie
          </Link>
          .
        </p>
        <CanyonRatingCard rating="3CI" title="Hall Creek" />
        <p>
          By day three all of us students and the instructors were accustomed to
          one another and so we cut the morning chatter short and went straight
          to <Link to="http://ropewiki.com/Hall_Creek">Hall Creek</Link>.
        </p>
        <p>
          I was the only person who didn't suit up at the beginning and the only
          person who brought a rain jacket for the <G>approach</G>.
          Consequently, I <em>really</em> learned these two lessons on the
          approach of this canyon.
        </p>
        <ul>
          <li>
            If the weather isn't hot and the approach is less than about 1.5
            miles, wear the <G>wetsuit</G> on the approach.
          </li>
          <li>
            Don't bother with a rain jacket because you'll sweat a ton and
            you'll be just as wet on the inside as you are on the outside.
          </li>
        </ul>
        <p>
          After the approach and gearing up, we took a look at the first drop of
          the canyon. This place was bolted to death. I was initially
          questioning the ethics and as usual there was a good explanation that
          makes lots of sense in a swift water context. There were bolts for
          low, medium, and high flows. There were also bolts to set up a{' '}
          <G>traverse line</G>. And this is apparently a <G>SAR</G> training
          canyon so I assume they placed additional bolts to suit their needs.
        </p>
        <Image
          image={data.P8070341}
          caption={
            <>
              Our instructors set up the traverse line and went over clipping in
              - short safety tether clips into both lines and the long one on
              the bottom line (picture is slightly incorrect) with the gates
              facing away from the rock so they don't get scraped.
            </>
          }
        />
        <Image
          image={data.P8070347}
          caption={
            <>
              <G>Bottom belay</G> is done the same in the <G>PNW</G> as it is in
              the <G>Colorado Plateau</G>
            </>
          }
        />
        <Image
          image={data.P8070354}
          caption={
            <>
              So is stuffing a <G>rope bag</G>
            </>
          }
        />
        <Image
          image={data.opengraph}
          caption={
            <>
              It was mind-boggling how slippery this canyon was. There could be
              a 15 degree slope and we would all have to crab walk or crawl to
              safely traverse. This is <em>real</em> slickrock.
            </>
          }
        />
        <Image
          image={data.P8070360}
          caption={
            <>
              Another mind-boggling thing were these <G>anchors</G>. Two
              appropriately spaced <G>bolts</G> with{' '}
              <G id="rappel-ring">steel rings</G>, no chains, and no{' '}
              <G>webbing</G>. More <G>American Death Triangle</G> than{' '}
              <G>EARNEST</G>. I don't think anyone knew the exact reason they
              were all like this but here are some speculations:
              <ul>
                <li>
                  <li>
                    SAR practices with them this way and we're just using their
                    bolts for <em>not</em> the intended purpose
                  </li>
                  <li>
                    They used to regularly have webbing but would get ripped off
                    in flooding events and become trash later in the canyon. So
                    no one uses webbing anymore.
                  </li>
                  <li>
                    Having chains thrash around during floods damages the rock
                  </li>
                </li>
              </ul>
            </>
          }
        />
        <Image
          image={data.P8070375}
          caption="One of the better rappels in the canyon. I gave up on trying to stand and just started sliding half-way down."
        />
        <Carousel
          images={[data.P8070387, data.P8070388, data.P8070389, data.P8070390]}
          captions={[
            <>
              See my{' '}
              <Link to={route.page_partner_capture}>Partner Capture</Link>{' '}
              article for detailed information on what's going on here. Start
              with hands on shins as you guide the capturee down. Capturee tries
              to apply as much friction as possible on the wall.
            </>,
            'While capturee is lowered walk hands up to thighs',
            "As soon as the capturee can reach, they put their hands on the capturer's shoulders",
            'Then they are down!',
          ]}
        />
        <Carousel
          images={[
            data.P8070394,
            data.P8070404,
            data.P8070385,
            data.P8070399,
            data.P8070425,
          ]}
          captions={[
            "I'll end with amazing views of Hall Canyon",
            'Another great rappel',
            'Frogs were everywhere',
            'As were these sluggy things - this one was probably 4 inches long',
            'Average size PNW tree used as anchors',
          ]}
        />
        <AllImagesLink id="2021-08-07-hall-creek" />
      </Layout>
    );
  }
}

export default withFrontMatter('2021-08-07-hall-creek')(
  Blog_2021_08_07_Hall_Creek
);

export const query = graphql`
  query {
    P8070341: file(
      relativePath: { eq: "images/blog/2021-08-07-hall-creek/P8070341.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    P8070347: file(
      relativePath: { eq: "images/blog/2021-08-07-hall-creek/P8070347.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    P8070354: file(
      relativePath: { eq: "images/blog/2021-08-07-hall-creek/P8070354.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    opengraph: file(
      relativePath: { eq: "images/opengraph/2021-08-07-hall-creek.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    P8070360: file(
      relativePath: { eq: "images/blog/2021-08-07-hall-creek/P8070360.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    P8070375: file(
      relativePath: { eq: "images/blog/2021-08-07-hall-creek/P8070375.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    P8070387: file(
      relativePath: { eq: "images/blog/2021-08-07-hall-creek/P8070387.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    P8070388: file(
      relativePath: { eq: "images/blog/2021-08-07-hall-creek/P8070388.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    P8070389: file(
      relativePath: { eq: "images/blog/2021-08-07-hall-creek/P8070389.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    P8070390: file(
      relativePath: { eq: "images/blog/2021-08-07-hall-creek/P8070390.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    P8070394: file(
      relativePath: { eq: "images/blog/2021-08-07-hall-creek/P8070394.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    P8070404: file(
      relativePath: { eq: "images/blog/2021-08-07-hall-creek/P8070404.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    P8070385: file(
      relativePath: { eq: "images/blog/2021-08-07-hall-creek/P8070385.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    P8070399: file(
      relativePath: { eq: "images/blog/2021-08-07-hall-creek/P8070399.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    P8070425: file(
      relativePath: { eq: "images/blog/2021-08-07-hall-creek/P8070425.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
